import React from "react";

export default function Undone() {
  return (
    <div id="undone">
      <div className="cont">
        <img src="./assets/building.jpg" alt="" />
        <h1>We are currently working on this page</h1>
      </div>
    </div>
  );
}
