import React from "react";
import CommingSoon from "./commingSoon";

export default function Utils({ data, setData }) {
  return (
    <div id="utils">
      {/* <CommingSoon /> */}
      <h1>🔐 This Feature is usable via Desktop version</h1>
      {/* <div className="content">
      </div> */}
    </div>
  );
}
