import React from "react";
export default function CommingSoon() {
  return (
    <div id="CommingSoon">
      <div className="cont">
        <img src="./assets/comming_soon.jpg" alt="" />
        <h1>Contact us to see it sooner</h1>
      </div>
    </div>
  );
}
